import React, { Component } from 'react';
import english from "../langs/en.translations.json";
import vietnam from "../langs/vi.translations.json";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { DateTimeEnum, LangEnum } from "../constants/enums";
import { Col, Layout, Row, Spin } from "antd";
import { pageRouter } from "../data/page-router";
import { Route, Routes } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import { ConnectedProps, connect } from "react-redux";
import Page404 from "./page-404";
import AppHeader from './app-header';
import { commonActions } from '../store/common/common.actions';
import { globalProps } from '../data/props';

const { Content, Footer } = Layout;

const mapStateToProps = (state: any) => {
  return {
    loadSpin: state.commonReducer.loadSpin,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({
    setFormatDatetime: commonActions.setFormatDatetime,
    setMerchantCode: commonActions.setMerchantCode,
    setStoreCode: commonActions.setStoreCode,
  }, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & LocalizeContextProps & {
  loadSpin?: any;
};

interface IState {
  lang: string,
  formatDatetime: any
};

class App extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    let query = new URLSearchParams(window.location.search);

    let langDefault = query.get("lang") || JSON.parse(localStorage.getItem("lang") as string) || LangEnum.EN;
    let formatDatetimeDefault = query.get("format_datetime") || JSON.parse(localStorage.getItem("format_datetime") as string) || DateTimeEnum.DD_MM_YYYY_HH_MM_SS_Z;

    localStorage.setItem("lang", JSON.stringify(langDefault));
    localStorage.setItem("format_datetime", JSON.stringify(formatDatetimeDefault));

    this.props.initialize({
      languages: [
        { code: LangEnum.EN, name: "English", },
        { code: LangEnum.VI, name: "Vietnam", },
      ],
      translation: vietnam,
      options: {
        onMissingTranslation: ({ translationId }) => translationId,
        renderToStaticMarkup: false,
        renderInnerHtml: true,
        defaultLanguage: langDefault,
      },
    });
    this.props.addTranslationForLanguage(vietnam, LangEnum.VI);
    this.props.addTranslationForLanguage(english, LangEnum.EN);
    this.state = {
      lang: langDefault,
      formatDatetime: formatDatetimeDefault
    };
    this.props.setFormatDatetime(formatDatetimeDefault);

  }

  componentDidMount() {
    this.checkUrl();
    this.handleWindowResize();
    window.addEventListener('resize', e => this.handleWindowResize());
  }

  checkUrl() {
    let query = new URLSearchParams(window.location.search);
    let merchantCode = query.get("merchantCode") || undefined;
    let storeCode = query.get("storeCode") || undefined;

    if (!merchantCode || !storeCode) {
      // window.location.href = 'https://smartbadminton.com/';
    }
    this.props.setMerchantCode(merchantCode || "");
    this.props.setStoreCode(storeCode || "");
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', e => this.handleWindowResize());
  }

  handleWindowResize() {
    let windowSize = this.getWindowSize();
    console.log(windowSize)
  }

  getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  onChangeLang(value: string) {
    this.setState({
      lang: value
    }, () => {
      localStorage.setItem("lang", JSON.stringify(value));
      this.props.setActiveLanguage(value);
    })
  }

  onChangeDatetime(value: string) {
    this.setState({
      formatDatetime: value
    }, () => {
      localStorage.setItem("format_datetime", JSON.stringify(value));
      this.props.setFormatDatetime(value);
    })
  }


  render() {
    let { lang, formatDatetime } = this.state;
    let { loadSpin, } = this.props;

    return (
      <Spin spinning={loadSpin > 0} size="large">
        <Layout>
          <AppHeader
            lang={lang}
            formatDatetime={formatDatetime}
            onChangeDatetime={(newDatetime) => this.onChangeDatetime(newDatetime)}
            onChangeLang={(newLang) => this.onChangeLang(newLang)}
          />
          <Content>
            <React.Suspense fallback={<Spin />}>
              <Routes>
                {pageRouter.map((k: any, i: number) => {
                  let Page = k.component;
                  return (
                    <Route
                      key={i}
                      path={k.path}
                      element={<Page />}
                    />
                  )
                }
                )}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </React.Suspense>
          </Content>
          <Footer>
            <Row {...globalProps.row12 as any}>
              <Col span={24}>
                <strong>Phone:</strong> <label><a href="tel:0789 499 919" >0789 499 919</a></label>
              </Col>
              <Col span={24}>
                <strong>Address:</strong> <label>An Lỗ, Phong Hiền, Phong Điền, TT Huế</label>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Spin>
    );
  }
}


export default connector(withLocalize(App));
